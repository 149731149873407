import React from "react";
import { motion } from "framer-motion";
import { Star, User } from "lucide-react";

// Reviews data for corporate and business feedback
const reviews = [
  {
    rating: 4,
    comment:
      "The gifting options for corporate events were versatile and unique. Really impressed!",
    name: "Priya S.",
    date: "August 15, 2023",
  },
  {
    rating: 5,
    comment:
      "The corporate training sessions organized by Hasiru Loka Green World were insightful and engaging!",
    name: "Ravi K.",
    date: "September 22, 2023",
  },
  {
    rating: 4,
    comment: "Loved the quality and eco-friendly approach of Hasiru Loka products.",
    name: "Anita B.",
    date: "October 5, 2023",
  },
  {
    rating: 5,
    comment: "Exceptional chocolates and training services! Highly recommended for businesses.",
    name: "Meera T.",
    date: "November 1, 2023",
  },
  {
    rating: 4,
    comment:
      "Great gifting options and eco-conscious approach. More international shipping options would be perfect!",
    name: "Rajesh P.",
    date: "December 12, 2023",
  },
];

const Testimonial = () => {
  const totalWidth = reviews.length * (256 + 32); // Calculate the total width required for the sliding effect

  return (
    <div className="bg-gray-100 py-16 px-6"> {/* Wrapper for background and spacing */}
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-4xl font-extrabold text-blue-600 mb-10 text-start"> {/* Section heading */}
          Testimonials
        </h2>
        <div className="relative overflow-hidden"> {/* Container for the animated slider */}
          <motion.div
            className="flex items-center space-x-8"
            animate={{
              x: [-totalWidth / 2, -totalWidth], // Sliding animation
            }}
            transition={{
              x: {
                repeat: Infinity, // Continuous loop
                repeatType: "loop",
                duration: 60, // Duration of the loop
                ease: "linear", // Linear movement for smooth scrolling
              },
            }}
          >
            {/* Duplicate reviews for seamless loop */}
            {[...reviews, ...reviews].map((review, index) => (
              <div
                key={index}
                className="flex-shrink-0 w-64 bg-white border border-gray-200 rounded-lg p-6 shadow hover:shadow-lg transition-shadow duration-300"
              >
                {/* Render stars based on the rating */}
                <div className="flex items-center mb-4">
                  {[...Array(5)].map((_, i) => (
                    <Star
                      key={i}
                      className={`h-5 w-5 ${
                        i < review.rating ? "text-yellow-400" : "text-gray-300"
                      }`}
                      fill={i < review.rating ? "currentColor" : "none"}
                    />
                  ))}
                </div>
                <p className="text-gray-700 mb-6 italic">"{review.comment}"</p> {/* Review comment */}
                <div className="flex items-center"> {/* Reviewer details */}
                  <User className="h-10 w-10 text-gray-400 mr-4" />
                  <div>
                    <p className="font-bold text-gray-900">{review.name}</p>
                  </div>
                </div>
              </div>
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;