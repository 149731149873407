import React from "react";
import image1 from "../../assets/dipit_logo.png";
import image2 from "../../assets/banner1.jpeg";
import image3 from "../../assets/banner3.jpeg";
import image4 from "../../assets/banner2.jpeg";
import image5 from "../../assets/grass1.jpeg";
import image6 from "../../assets/grass2.jpeg";
import image7 from "../../assets/grass5.jpeg";
import image8 from "../../assets/grass6.jpeg";
import image9 from "../../assets/corporate-gift-2.jpeg";
import image10 from "../../assets/Gift-Sets.png";
import image11 from "../../assets/gift.jpg";
import image12 from "../../assets/Gift-Sets.png";
import image13 from "../../assets/cp5.jpg";
import image14 from "../../assets/cp3.jpg";
import image15 from "../../assets/cp4.jpg";
import image16 from "../../assets/cp.jpg";
import image17 from "../../assets/praestans1.jpeg";
import image18 from "../../assets/praestans5.jpeg";
import image19 from "../../assets/praestans3.jpeg";
import image20 from "../../assets/praestans6.jpeg";

const items = [
  {
    title: "Corporate Gifting",
    items: [
      "Executive Gift Sets: Luxurious combinations of office supplies, tech gadgets, and gourmet treats to impress clients and employees.",
      "Personalized Gifts: Customized notebooks, pens, mugs, and other branded merchandise with your logo or heartfelt message.",
      "Eco-Friendly Options: Sustainable gifts such as reusable tote bags, bamboo products, and plant kits to showcase your commitment to the environment.",
      "Tech-Driven Gifting: Innovative tech gifts like smart gadgets, wireless chargers, and noise-canceling headphones for the modern professional.",
      "Event-Specific Kits: Tailored kits for conferences, workshops, and trade shows to make a memorable impact.",
    ],
    images: [image9, image10, image11, image12],
    alt: "Corporate Gifting",
  },
  {
    title: "DIPIT - Chocolate Business",
    items: [
      "Handcrafted Chocolates: Exquisite artisanal chocolates made with premium ingredients for a luxurious treat.",
      "Customized Chocolate Boxes: Personalized packaging and unique flavors for corporate gifting or special occasions.",
      "Chocolate Hampers: Curated gift baskets featuring a variety of chocolate treats and accompaniments.",
      "Event-Specific Chocolates: Unique themes and designs tailored to weddings, festivals, or business events.",
      "Healthy Alternatives: A range of dark chocolates, sugar-free options, and nutrient-enriched varieties for health-conscious consumers.",
    ],
    images: [image1, image2, image3, image4],
    alt: "Chocolate Business",
  },
  {
    title: "Hasiru Loka Green World",
    items: [
      "Innovative Eco-Products: High-quality, sustainable goods designed to reduce the environmental footprint.",
      "Urban Gardening Kits: Comprehensive kits to help individuals and families grow their own vegetables and herbs in limited spaces.",
      "Educational Workshops: Interactive sessions to spread awareness about sustainability and eco-friendly practices.",
      "Zero-Waste Solutions: Products that promote a zero-waste lifestyle, including reusable and compostable alternatives.",
      "Eco-Community Initiatives: Partnerships and campaigns aimed at creating a greener, cleaner world.",
    ],
    images: [image5, image6, image7, image8],
    alt: "Eco-Friendly Solutions",
  },
  {
    title: "Corporate Training",
    items: [
      "Leadership Development: Programs to enhance leadership skills and team management.",
      "Effective Communication: Workshops focused on improving verbal and non-verbal communication in professional settings.",
      "Time Management Skills: Strategies to optimize productivity and prioritize tasks effectively.",
      "Diversity and Inclusion Training: Promoting awareness and fostering inclusive workplace practices.",
      "Stress Management Techniques: Tools to manage workplace stress and maintain mental well-being.",
    ],
    images: [image13, image14, image15, image16],
    alt: "Corporate Training Initiatives",
  },
  {
    title: "Praestans",
    items: [
      "Elegant Jewelry Designs: Unique, handcrafted jewelry pieces that blend sophistication with timeless style.",
      "Premium Stationery Sets: Luxurious stationery items including notebooks, planners, and writing tools for professionals and enthusiasts.",
      "Customized Gifting Solutions: Personalized jewelry and stationery to commemorate special occasions.",
      "Eco-Friendly Materials: Sustainable and responsibly sourced materials for both jewelry and stationery.",
      "Workshops and Events: Interactive sessions on jewelry design and stationery crafting to inspire creativity.",
    ],
    images: [image17, image18, image19, image20],
    alt: "Stationery and Jewelry Business",
  },
];

const OurBusiness = () => {
  return (
    <div className="bg-white">
      <div className="mx-auto py-16 px-4 sm:px-6 lg:px-8 max-w-7xl">
        <div className="lg:gap-x-8">
          <div className="w-full mx-auto p-6">
            <h2 className="text-4xl font-extrabold text-blue-600 mb-4 text-start">
              OUR BUSINESS
            </h2>
            <p className="text-lg text-gray-500 text-justify mb-4">
              In today's dynamic business environment, creating lasting
              impressions is essential. At Mandri Enterprise, we strive to
              redefine the art of gifting with innovative, high-quality, and
              eco-conscious solutions. Whether it’s corporate gifting,
              personalized return gifts, premium chocolate hampers, or
              eco-friendly lifestyle products, we ensure that every offering
              resonates with your values and elevates your relationships.
            </p>
            <p className="text-lg text-gray-500 text-justify mb-8">
              Our commitment to sustainability and customer satisfaction sets us
              apart. We constantly explore new trends and technologies to
              deliver exceptional products that align with the latest market
              demands. By choosing us, you contribute to a greener planet and
              foster meaningful connections with your stakeholders.
            </p>
            <div className="space-y-12">
              {items?.map((section, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center space-y-6"
                >
                  <h3 className="text-2xl font-bold text-gray-800 mb-4">
                    {section.title}
                  </h3>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                    {section.images.map((image, imgIndex) => (
                      <img
                        key={imgIndex}
                        src={image}
                        alt={section.alt}
                        className="rounded-lg shadow-lg w-full h-64 object-cover"
                      />
                    ))}
                  </div>
                  <div className="text-center">
                    <ul className="list-disc list-inside text-gray-600 space-y-3 text-lg text-justify">
                      {section.items.map((item, idx) => (
                        <li key={idx}>{item}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurBusiness;
