import React from "react";
import contact from "../../assets/support.webp";

const ContactUs = () => {
  return (
    <div className="flex flex-col md:flex-row items-center md:items-start p-6 md:p-12 bg-gray-50">
      <h2 className="text-4xl font-semibold mb-6 text-blue-600 mt-4 lg:hidden sm:block">
        Contact Information
      </h2>
      {/* Image Section */}
      <div className="w-full md:w-1/2 mb-6 md:mb-0 md:pr-6">
        <img
          src={contact}
          alt="Support"
          className="w-full h-auto rounded-lg shadow-md"
        />
      </div>

      {/* Contact Information Section */}
      <div className="w-full md:w-1/2 text-left">
        <h2 className="text-4xl font-semibold mb-6 text-blue-600 md:block hidden">
          Contact Information
        </h2>
        <div className="space-y-4">
          <p className="text-gray-700 text-base md:text-lg">
            <strong className="font-semibold text-gray-900">Address:</strong>
            <br />
            #25, 29th Main Road, BTM Layout 2nd Stage, Bangalore - 560076
          </p>
          <p className="text-gray-700 text-base md:text-lg">
            <strong className="font-semibold text-gray-900">Location:</strong>
            <br />
            Nexus Whitefield Mall, 3rd Floor
          </p>
          <p className="text-gray-700 text-base md:text-lg">
            <strong className="font-semibold text-gray-900">Email:</strong>
            <br />
            marvind7@gmail.com
          </p>
          <p className="text-gray-700 text-base md:text-lg">
            <strong className="font-semibold text-gray-900">Phone:</strong>
            <br />
            +91 9900837767
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
