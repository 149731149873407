import React from "react";
import profileImg from "../../assets/myprofile.jpeg";

const Profile = () => {
  return (
    <div className="bg-gray-50 px-6 md:p-12 rounded-lg shadow-lg py-2">
      <h2 className="text-2xl font-semibold mb-6 text-blue-600 sm:block lg:hidden mt-2">
        Founder
      </h2>

      {/* Main Container */}
      <div className="flex flex-col md:flex-row items-center md:items-start gap-6 ">
        {/* Profile Image Section */}
        <div className="md:w-1/2 flex justify-center">
          <img
            src={profileImg}
            alt="Profile"
            className="w-64 h-80 md:w-[350px] md:h-[500px] object-cover border-4 border-gray-300 rounded-lg shadow-lg"
          />
        </div>

        {/* Business Interests Section */}
        <div className="text-left md:w-1/2">
          <h2 className="text-4xl font-semibold mb-6 text-blue-600 md:block hidden">
            Founder
          </h2>
          <p className="text-gray-700 text-base md:text-lg mb-4">
            Arvind M is a multifaceted entrepreneur involved in various business
            ventures across different sectors. His business interests include:
          </p>
          <div className="space-y-4">
            <p className="text-gray-700 text-base md:text-lg">
              <strong className="font-semibold text-gray-900">
                Corporate Gifting:
              </strong>
              <br />
              Arvind has developed a niche in the corporate gifting market,
              offering a wide range of personalized and premium gifts tailored
              to meet the needs of businesses and their clients. This segment
              focuses on helping organizations enhance their brand image through
              thoughtful and branded gift solutions.
            </p>
            <p className="text-gray-700 text-base md:text-lg">
              <strong className="font-semibold text-gray-900">
                Dipit - Chocolate:
              </strong>
              <br />
              Arvind runs a chocolate brand called Dipit, which specializes in
              crafting high-quality chocolates. The brand offers a variety of
              chocolate products, catering to both retail and corporate clients,
              often serving as unique gift items or indulgences.
            </p>
            <p className="text-gray-700 text-base md:text-lg">
              <strong className="font-semibold text-gray-900">
                Praestans:
              </strong>
              <br />
              Another area where Arvind is making a mark is in the lawn grass
              business. His company provides high-quality grass for landscaping,
              residential, and commercial purposes, contributing to outdoor
              beautification and maintenance solutions.
            </p>
            <p className="text-gray-700 text-base md:text-lg">
              <strong className="font-semibold text-gray-900">
                Corporate Training:
              </strong>
              <br />
              Arvind also ventures into corporate training, providing skill
              development and leadership programs for businesses. His training
              services focus on enhancing employee performance, leadership
              capabilities, and organizational growth.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
