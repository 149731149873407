import React from 'react';
import Carousel from '../component/home/Carousel';
import { useInView } from 'react-intersection-observer';
import AboutUs from '../component/home/AboutUs';
// import Business from '../component/home/Business';
import Testimonial from '../component/home/Testimonial';
import Profile from '../component/home/Profile';
import OurBusiness from '../component/home/OurBusiness';
import ContactUs from '../component/home/ContactUs';

const Home = () => {
  const [carouselRef, carouselInView] = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <>
      <div ref={carouselRef} className={`transition-opacity duration-1000 ${carouselInView ? 'opacity-100' : 'opacity-0'}`}>
        <Carousel />
      </div>
      <div>
        <AboutUs/>
        <OurBusiness/>
        {/* <Business/> */}
        <Profile/>
        <Testimonial/>
        <ContactUs/>
      </div>
     
    </>
  );
};

export default Home;