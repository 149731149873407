import React from "react";

const AboutUs = () => {
  return (
    <div className="bg-gray-50 flex flex-col items-start justify-start space-y-4 px-6 ">
      <div className="mx-auto py-16 px-4 sm:px-6 lg:px-8 max-w-7xl">
        <h1 className="text-4xl font-bold text-start text-blue-600 mb-6">
          About Us
        </h1>
        <p className="text-gray-700 text-lg mb-4">
          Welcome to <strong>Mandri Enterprises</strong>, where innovation,
          quality, and sustainability come together. We are proud to offer three
          unique ventures that cater to diverse needs:
        </p>
        <ul className="list-disc pl-6 text-gray-700 text-lg">
          <li className="mb-2">
            <strong>Corporate Gifting</strong> - Offering luxurious,
            personalized, and eco-friendly gift options to strengthen
            relationships and leave lasting impressions.
          </li>
          <li className="mb-2">
            <strong>Dipit - Chocolate Business</strong> - Providing handcrafted,
            premium chocolates, customized packaging, and healthy alternatives
            for every occasion.
          </li>
          <li>
            <strong>Hasiru Loka Green World</strong> - Promoting eco-conscious
            living through sustainable products, urban gardening kits, and
            zero-waste solutions.
          </li>
        </ul>
        <div className="mt-6">
          <h2 className="text-2xl font-semibold text-blue-500 mb-4">
            Why Choose Us?
          </h2>
          <p className="text-gray-700">
            At Mandri Enterprises, we strive to redefine the art of gifting and
            lifestyle with a commitment to sustainability and customer
            satisfaction. From premium corporate gifts to eco-friendly solutions
            and delightful chocolates, our offerings are designed to create
            memorable experiences while contributing to a greener planet.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
