import React from 'react';
import logo from '../../assets/logo4.png';

export default function Navbar() {
  return (
    <nav className="bg-white shadow-md sticky top-0 z-50">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-20">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <img className="h-28 w-auto py-4" src={logo} alt="Company Logo" />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
